import React, { useEffect, useMemo } from 'react';
import { purchaseCartProductType, SupplierDetails, SupplierType } from 'types/supplier.types';
import { RequiredProductInterface } from 'components/products/excel-reader/cartExcelUtils/excel-reader-cart.types';
import { roundPrice } from 'utils/number';
import { useSuppliers } from 'hooks/suppliers/queries';
import { fixInitialSuppliers } from 'components/screens/purchase/cart/suppliersOptions';

type Product = {
  id: number;
  supplier: number;
  quantity: number;
};

type CartContextValue = {
  cart: Product[];
  suppliers: SupplierType[];
  addToCart: (supplierId: number, product: Product) => void;
  addOrdersIds: (supplierId: number, ordersIds: number[]) => void;
  removeFromCart: (supplierId: number, productId: any) => void;
  removeOrderProductFromCart: (
    supplierId: number,
    productId: any,
    detailId: number
  ) => void;
  updateProductQuantity: (
    supplierId: number,
    productId: number,
    newQuantity: number
  ) => void;
  updateProductPrice: (
    supplierId: number,
    productId: number,
    newPrice: number
  ) => void;
  cleanSupplier: (supplierId: number) => void;
  addProductToSupplierDetails: (
    supplierId: number,
    productId: any,
    excelRow?: RequiredProductInterface
  ) => void;
  modalState: any;
  updateModalState: (newModalState: {}) => void;
  unfindedExcelProducts: RequiredProductInterface[],
  setUnfindedExcelProducts: React.Dispatch<React.SetStateAction<RequiredProductInterface[]>>
};

const initialCartContextValue: CartContextValue = {
  cart: [],
  suppliers: [{
    id: 0,
    name: '',
    total: '',
    availableCredit: '0',
    totalUnits: 0,
    details: [],
  }],
  addToCart: () => { },
  addOrdersIds: () => { },
  removeFromCart: () => { },
  removeOrderProductFromCart: () => { },
  updateProductQuantity: () => { },
  updateProductPrice: () => { },
  cleanSupplier: () => { },
  addProductToSupplierDetails: () => { },
  modalState: {},
  updateModalState: () => { },
  unfindedExcelProducts: [],
  setUnfindedExcelProducts: () => { }
};

const SESSION_STORAGE_KEY = 'cart';

export const CartContext = React.createContext<CartContextValue>(
  initialCartContextValue
);

const CartProvider: React.FC = ({ children }) => {
  const [cart, setCart] = React.useState<any[]>(() => {
    const storedCart = localStorage.getItem(SESSION_STORAGE_KEY);
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const { data: suppliersData } = useSuppliers();

  const suppliersInitialState = useMemo(() => {
    return fixInitialSuppliers({ suppliersData }).suppliersInitialState;
  }, [suppliersData]);


  const [suppliers, setSuppliers] = React.useState<SupplierType[]>(() => {
    const storedSuppliers = localStorage.getItem('suppliers');
    return storedSuppliers?.length
      ? JSON.parse(storedSuppliers)
      : suppliersInitialState;
  });

  useEffect(() => {
    setSuppliers(suppliersInitialState);
  }, [suppliersInitialState])


  const [modalState, setModalState] = React.useState<{}>({});
  const [unfindedExcelProducts, setUnfindedExcelProducts] = React.useState<RequiredProductInterface[]>([])

  const updateModalState = (newState: any) => {
    setModalState((prevState: any) => {
      return {
        ...prevState,
        ...newState,
      };
    });
  };

  const addToCart = (supplierId: number, product: any) => {
    setCart((prevCart) => {
      const updatedCart = [
        ...(prevCart ?? []),
        {
          ...product,
          id: product.product.id,
          detailId: product.id,
        },
      ];
      localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(updatedCart));

      const supplier = suppliers.find((obj: any) => obj.id === supplierId);
      if (supplier) {
        const newProduct: SupplierDetails = {
          id: product.product.id.toString(),
          idDetail: product.id,
          name: product?.product.base.name,
          description: product?.product?.description,
          ean: product?.product?.ean_key,
          status: '',
          quantity: product?.quantity,
          has_iva: product?.product?.has_iva,
          price:
            product?.product?.supplier_products?.find(
              (product: any) => product.supplier.id === supplierId
            )?.cost ||
            product?.cost ||
            0,

          image: product?.product?.default_image,
          approvalDate: '',
          supplier: supplierId,
          deliveryPromise: '',
          product: product.product.id,
          order: [product.order],
        };

        if (supplier.details.some((elem) => elem.id === newProduct.id)) {
          const detailIndex = supplier.details.findIndex(
            (detail: any) => detail.id === newProduct.id
          );
          const updatedDetails = [...supplier.details];
          updatedDetails[detailIndex].quantity += newProduct.quantity;
          updatedDetails[detailIndex].order.push(product.order);
          supplier.details = updatedDetails;
        } else {
          supplier.details.push(newProduct);
        }
        setSuppliers([
          ...suppliers.filter((obj) => obj.id !== supplierId),
          supplier,
        ]);
        localStorage.setItem('suppliers', JSON.stringify(suppliers));
      }

      return updatedCart;
    });
  };

  const addOrdersIds = (supplierId: number, ordersIds: number[]) => {
    const supplier = suppliers.find((obj) => obj.id === supplierId);
    if (supplier) {
      supplier.rebuyOrdersIds = ordersIds;
    }
  };

  // this function is for rail, it delete the product from the supplier details, and all of orders that have the product
  const removeFromCart = (supplierId: number, productToDelete: any) => {
    const prevCart = cart;

    const newCart = prevCart?.filter(
      (product) =>
        product.supplier !== supplierId && product.detailId !== productToDelete
    );

    localStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify(newCart?.length ? newCart : [])
    );

    setCart(newCart);

    const supplier = suppliers.find((obj) => obj.id === supplierId);

    if (supplier) {
      const newSupplierDetails = supplier.details.filter(
        (detail) => detail.id !== productToDelete
      );

      supplier.details = newSupplierDetails;
      setSuppliers([...suppliers]);
      localStorage.setItem('suppliers', JSON.stringify(suppliers));
    }
  };

  const removeOrderProductFromCart = (
    supplierId: number,
    productToDelete: any
  ) => {
    const prevCart = cart;

    const newCart = prevCart?.filter(
      (product) => product.detailId !== productToDelete.id
    );

    localStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify(newCart?.length ? newCart : [])
    );

    setCart(newCart);

    const supplier = suppliers.find((obj) => obj.id === supplierId);

    if (supplier) {
      const productIndex = supplier.details.findIndex(
        (detail) => detail.product === productToDelete.product.id
      );

      supplier.details[productIndex].quantity = productToDelete.quantity;

      if (supplier.details[productIndex].quantity === 0) {
        supplier.details.splice(productIndex, 1);
      }
      setSuppliers([...suppliers]);
      localStorage.setItem('suppliers', JSON.stringify(suppliers));
    }
  };

  const updateProductQuantity = (
    supplierId: number,
    productId: number,
    newQuantity: number
  ) => {
    setSuppliers((prevSuppliers) => {
      const updatedSuppliers = prevSuppliers.map((supplier) => {
        if (supplier.id === supplierId) {
          const detailIndex = supplier.details.findIndex(
            (detail: any) => detail.id === productId.toString()
          );

          if (detailIndex !== -1) {
            const updatedDetails = [...supplier.details];
            updatedDetails[detailIndex].quantity = newQuantity;

            return {
              ...supplier,
              details: updatedDetails,
            };
          }
        }

        return supplier;
      });

      localStorage.setItem('suppliers', JSON.stringify(updatedSuppliers));
      return updatedSuppliers;
    });
  };

  const updateProductPrice = (
    supplierId: number,
    productId: number,
    newPrice: number
  ) => {
    setSuppliers((prevSuppliers) => {
      const updatedSuppliers = prevSuppliers.map((supplier) => {
        if (supplier.id === supplierId) {
          const detailIndex = supplier.details.findIndex(
            (detail: any) => detail.id === productId.toString()
          );

          if (detailIndex !== -1) {
            const updatedDetails = [...supplier.details];
            updatedDetails[detailIndex].price = String(newPrice);

            return {
              ...supplier,
              details: updatedDetails,
            };
          }
        }

        return supplier;
      });

      localStorage.setItem('suppliers', JSON.stringify(updatedSuppliers));
      return updatedSuppliers;
    });
  };

  const addProductToSupplierDetails = async (
    supplierId: number,
    product: any,
    // excelRow is used in ExcelReacerCart.tsx, handles adding to card using EAN instead of product.id
    // Request using ean_key and response comes in pagination; handle it as a pagination response
    excelRow?: RequiredProductInterface
  ) => {
    let purchaseCartProduct: purchaseCartProductType = {
      id: '',
      name: '',
      description: '',
      ean_key: '',
      image: ''
    }

    try {
      const response = await fetch(
        excelRow
          ? `${process.env.REACT_APP_API_URL}/products/?ean_key__in=${String(excelRow.Ean)}`
          : `${process.env.REACT_APP_API_URL}/products/${product?.id}`,
        {
          headers: {
            'X-Api-Key': `${process.env.REACT_APP_API_TOKEN}`,
          }
        }
      );
      const data = await response.json();

      if (data?.results?.length === 0 && excelRow) {
        setUnfindedExcelProducts((prev) => [...prev, excelRow])
        return
      }

      purchaseCartProduct = excelRow
        ? {
          id: data.results[0]?.id,
          name: data.results[0]?.base.name,
          description: data.results[0]?.presentation,
          ean_key: data.results[0]?.ean_key,
          image: data.results[0]?.default_image,
          has_iva: data.results[0]?.has_iva
        }
        : {
          id: product?.id.toString(),
          name: product?.name,
          description: product?.description,
          ean_key: product?.ean_key,
          image: product?.default_image
        }

      const handleCalculateExcelPrice = () => {
        if (excelRow) {
          return purchaseCartProduct?.has_iva ? (excelRow.Costo * 1.16) : excelRow?.Costo
        }

        return undefined
      }

      setSuppliers((prevSuppliers) => {
        const supplierIndex = prevSuppliers.findIndex(
          (supplier) => supplier?.id === supplierId
        );

        if (supplierIndex === -1) return prevSuppliers;

        const supplier = prevSuppliers[supplierIndex];
        const existingProductIndex = supplier.details.findIndex(
          (detail) => detail.id === purchaseCartProduct.id
        );

        if (existingProductIndex === -1) {
          const newProduct: SupplierDetails = {
            id: purchaseCartProduct.id,
            idDetail: '',
            name: purchaseCartProduct.name,
            description: purchaseCartProduct.description,
            ean: purchaseCartProduct.ean_key,
            status: '',
            quantity: excelRow ? excelRow.Cantidad : 1,
            price: String(roundPrice(handleCalculateExcelPrice() || Number(data?.supplier_products?.find(
              (p: any) => p.supplier.id === Number(supplierId)
            )?.cost) || 0)),
            image: purchaseCartProduct.image,
            approvalDate: '',
            supplier: supplierId,
            deliveryPromise: '',
            product: Number(purchaseCartProduct.id),
            // this is meant to try fix the order status update
            // order: excelRow?.Orden ? [excelRow.Orden] : undefined,
            ...(excelRow?.Orden ? { order: [excelRow.Orden] } : {}),
            has_iva: purchaseCartProduct?.has_iva
          };

          const updatedSupplierDetails = [...supplier.details, newProduct];
          const updatedSupplier = {
            ...supplier,
            details: updatedSupplierDetails,
          };
          const updatedSuppliers = [...prevSuppliers];
          updatedSuppliers[supplierIndex] = updatedSupplier;

          localStorage.setItem('suppliers', JSON.stringify(updatedSuppliers));
          return updatedSuppliers;
        }

        const existingProduct = supplier.details[existingProductIndex];

        const updatedExistingProduct = {
          ...existingProduct,
          quantity: existingProduct.quantity + 1,
          // when excel file is uploaded, we save the orders id to
          // change statuses in APPROVED ones, to BOUGHT
          // (same product could have multiple order destinies)
          order:
            excelRow?.Orden && !existingProduct.order?.includes(excelRow?.Orden)
              ? existingProduct.order?.concat(excelRow.Orden)
              : existingProduct.order
        };

        const updatedSupplierDetails = [...supplier.details];

        updatedSupplierDetails[existingProductIndex] = updatedExistingProduct;

        const updatedSupplier = {
          ...supplier,
          details: updatedSupplierDetails,
        };

        const updatedSuppliers = [...prevSuppliers];

        updatedSuppliers[supplierIndex] = updatedSupplier;

        localStorage.setItem('suppliers', JSON.stringify(updatedSuppliers));
        return updatedSuppliers;
      });

    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching product details:', error);
    }

    // set cart item

    setCart((prevCart) => {
      const productCart = { id: purchaseCartProduct.id, supplier: supplierId };

      const cartItemExists = prevCart.some(
        (item) =>
          item.id === productCart.id && item.supplier === productCart.supplier
      );

      if (cartItemExists) {
        return prevCart;
      }
      const updatedCart = [...prevCart, productCart];
      localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const cleanSupplier = (supplierId: number) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(
        (product) => product.supplier !== supplierId
      );
      localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(updatedCart));

      const supplier = suppliers.find((obj) => obj.id === supplierId);

      if (supplier) {
        supplier.details = [];
        supplier.rebuyOrdersIds = [];

        setSuppliers([...suppliers]);
        localStorage.setItem('suppliers', JSON.stringify(suppliers));
      }

      return updatedCart;
    });
  };

  // React.useEffect(() => {
  //   // Simulamos una llamada a una API para obtener los proveedores
  //   const fetchSuppliers = async () => {
  //     const response = await fetch('/api/suppliers');
  //     const data = await response?.json();
  //     setSuppliers(data);
  //     localStorage.setItem('suppliers', JSON.stringify(data));
  //   };
  //   fetchSuppliers();
  // }, []);

  const cartContextValue: CartContextValue = React.useMemo(
    () => ({
      cart,
      suppliers,
      addToCart,
      addOrdersIds,
      removeFromCart,
      removeOrderProductFromCart,
      updateProductQuantity,
      updateProductPrice,
      cleanSupplier,
      addProductToSupplierDetails,
      modalState,
      updateModalState,
      unfindedExcelProducts,
      setUnfindedExcelProducts
    }),
    [cart, suppliers]
  );

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
