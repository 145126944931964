import React, { createContext } from 'react';
import { useStores } from 'hooks/stores/queries';
import { Store } from 'types/stores.types';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useAuth } from 'hooks';
import { isDoctor, isOperatorAlly } from 'utils/checkRole';
import useOperatorCedis, { organizationAlliesEnum } from 'hooks/operatorAllyUtils/useOperatorCedis';

type ISetState = (store: Store) => void;
type IStorehouseData = {
  selected: Store | undefined;
  stores: Store[];
};

type StorehouseContext = [IStorehouseData, ISetState];

const initialContext: StorehouseContext = [
  { selected: undefined, stores: [] },
  () => undefined,
];
const StorehouseContext = createContext<StorehouseContext>(initialContext);

const StorehouseProvider: React.FC = (props) => {
  const [authState] = useAuth();
  const { user } = authState;

  // this state saves the selected store
  const [state, setState] = useLocalStorage<Store | undefined>(
    'firstLogin',
    undefined
  );
  const [stores, setStorehouseSelectOptions] = React.useState<Store[]>([]);

  const isOperator = isOperatorAlly(user?.groups ?? []);
  const isDoctorUser = isDoctor(user?.groups ?? []);

  const operatorStores = useOperatorCedis(stores, user?.organization?.name)

  const setStore = (store: Store) => {
    setState(store)
  };

  const { data: storesData } = useStores();
  const filteredStores = storesData?.stores.filter(
    (store) =>
      // store.name !== 'Sucursal Cd. Mx.' &&
      store.name !== 'Amazon Seller Flex CDMX'
  );


  React.useEffect(() => {
    if (filteredStores?.length) {
      setStorehouseSelectOptions(filteredStores);
    }

    if (isDoctorUser && user?.organization?.name !== organizationAlliesEnum.HOSPITARIA) {
      const doctorStores = storesData?.stores.filter(
        (store) => store.id === 1 || store.id === 2
      );
      if (doctorStores?.length === 2) {
        setStorehouseSelectOptions(doctorStores);
      }
    }
  }, [storesData?.stores.length, user]);

  React.useEffect(() => {
    if (isOperator && stores.length) {

      // in operatorStore we save de actual storehouse of the ally to set it as default in the StorehouseSelect
      const operatorStore = stores.find(
        (store) => store.name === user?.organization?.name
      );

      // once we found it, we set all the options using operatorStores dictionaries
      if (operatorStore) {
        setStorehouseSelectOptions(() => [
          ...operatorStores ?? []
        ]);

        // if operator organization does not have any cedis, then use the one defined in operatorStores
        // InstitutoOftalmologiaCedis is an example of this case
        if (operatorStores && !operatorStores?.includes(operatorStore)) {
          setStore(operatorStores[0])
          return
        }

        setStore(operatorStore);
      }
    }
  }, [isOperator, stores.length]);

  const contextValue: StorehouseContext = React.useMemo(
    () => [{ stores, selected: state }, setStore],
    [stores, state]
  );

  const { children } = props;

  return (
    <StorehouseContext.Provider value={contextValue}>
      {children}
    </StorehouseContext.Provider>
  );
};

export { StorehouseContext };
export default StorehouseProvider;
