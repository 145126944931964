import { Store, storesNameEnum } from 'types/stores.types';

export enum organizationAlliesEnum {
  HOSPITARIA = 'Hospitaria',
  FLORA_FERTILIDAD = 'Flora Fertilidad',
  SWISS = 'Swiss Hospital',
  PRIMER_FRENTE = 'Primer Frente',
  BALAAM = 'Balaam',
  OSSIS = 'Ossis',
  BMSA = 'BMSA',
  CODEFAR = 'CODEFAR',
  KONIGSBERG = 'Konigsberg',
  A5 = 'A5 Express',
  INSTITUTO_OFTALMOLOGIA = 'Instituto de Oftalmología',
  CENTRO_EXCELENCIA_SALUD_MENTAL = 'Centro Excelencia Salud Mental'
}

const HospitariaCedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
  storesNameEnum.HOSPITARIA,
  storesNameEnum.HOSPITARIA_CAIH,
  storesNameEnum.HOSPITARIA_VITAU,
];

const FloraOperatorCedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
  storesNameEnum.FLORA_FERTILIDAD,
];

const SwissOperatorCedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
  storesNameEnum.SWISS_HOSPITAL,
];

const PrimerFrenteCedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
  storesNameEnum.PRIMER_FRENTE,
];

const OssisCedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
  storesNameEnum.OSSIS,
];

const CodefarCedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
  storesNameEnum.CODEFAR,
];

const A5Cedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
  storesNameEnum.A5_EXPRESS,
];

const InstitutoOftalmologiaCedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
];

const CentroExcelenciaSaludMentalCedis = [
  storesNameEnum.SUCURSAL_MONTERREY,
  storesNameEnum.SUCURSAL_CDMX,
];

const useOperatorCedis = (
  stores: Store[] | undefined,
  userOrganization: organizationAlliesEnum | undefined) => {

  switch (userOrganization) {
    case organizationAlliesEnum.HOSPITARIA:
      return stores?.filter((store) => HospitariaCedis.includes(store.name as storesNameEnum))

    case organizationAlliesEnum.FLORA_FERTILIDAD:
      return stores?.filter((store) => FloraOperatorCedis.includes(store.name as storesNameEnum))

    case organizationAlliesEnum.SWISS:
      return stores?.filter((store) => SwissOperatorCedis.includes(store.name as storesNameEnum))

    case organizationAlliesEnum.PRIMER_FRENTE:
      return stores?.filter((store) => PrimerFrenteCedis.includes(store.name as storesNameEnum))

    case organizationAlliesEnum.OSSIS:
      return stores?.filter((store) => OssisCedis.includes(store.name as storesNameEnum))

    case organizationAlliesEnum.CODEFAR:
      return stores?.filter((store) => CodefarCedis.includes(store.name as storesNameEnum))

    case organizationAlliesEnum.A5:
      return stores?.filter((store) => A5Cedis.includes(store.name as storesNameEnum))

    case organizationAlliesEnum.INSTITUTO_OFTALMOLOGIA:
      return stores?.filter((store) => InstitutoOftalmologiaCedis.includes(store.name as storesNameEnum))

    case organizationAlliesEnum.CENTRO_EXCELENCIA_SALUD_MENTAL:
      return stores?.filter((store) => CentroExcelenciaSaludMentalCedis.includes(store.name as storesNameEnum))

    default:
      return []
  }
}

export default useOperatorCedis

// "Flora Fertilidad",
// "Sucursal Monterrey",
// "Mercado Libre FULL",
// "Ossis",
// "Hospitaria",
// "Amazon FULL",
// "Amazon Seller Flex CDMX",
// "Amazon Seller Flex MTY",
// "Hospitaria CAIH",
// "Coppel",
// "Sucursal Cd. Mx.",
// "Hospitaria Vitau",
// "Swiss Hospital",
// "Primer Frente",
