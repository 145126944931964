import { gql } from '@apollo/client';
import fragments from '../orders.fragments';

export const ORDERS_GET = gql`
  query orders(
    $patient: String
    $distribution_center: String
    $expected_delivery_date_after: String
    $payment_status__in: String
    $payment_date_before: String
    $payment_date_after: String
    $referred_by: String
  ) {
    orders(
      patient: $patient
      distribution_center: $distribution_center
      expected_delivery_date_after: $expected_delivery_date_after
      payment_status__in: $payment_status__in
      payment_date_before: $payment_date_before
      payment_date_after: $payment_date_after
      referred_by: $referred_by
    ) @rest(type: "[Order]", path: "/orders/?{args}") {
      ...OrderFragment
    }
  }

  ${fragments.order}
`;

export const ORDERS_GET_PAGINATED = gql`
  query ordersPaginated(
    $limit: String
    $page: String
    $search: String
    $ordering: String
    $id__in: String
    $order_status__in: String
    $shipping_method: String
    $payment_status__in: String
    $expected_delivery_date_after: String
    $expected_delivery_date_before: String
    $patient: String
    $payment_method: String
    $is_subscription: Boolean
    $subscription: String
    $is_doctor: Boolean
    $requires_prescription: Boolean
    $origin: String
    $has_enlace_vital: Boolean
    $has_cdso: Boolean
    $has_loyalty_error: Boolean
    $in_process_date: String
    $max_delivery_date: String
    $expected_delivery_date: String
    $driver: Number
    $supervised_by: Number
    $distribution_center: String
    $exclude_order_status__in: String
    $has_cfdi: Boolean
    $has_invoicing: Boolean
    $credit_date: String
    $payment_date: String
    $is_special_pickup: Boolean
    $store: String
    $external_payment_id: String
    $payment_date_before: String
    $payment_date_after: String
    $detail_ean_key: String
    $supervised_by: String
  ) {
    ordersPaginated(
      limit: $limit
      page: $page
      search: $search
      ordering: $ordering
      id__in: $id__in
      order_status__in: $order_status__in
      payment_status__in: $payment_status__in
      expected_delivery_date_after: $expected_delivery_date_after
      expected_delivery_date_before: $expected_delivery_date_before
      payment_date_before: $payment_date_before
      payment_date_after: $payment_date_after
      patient: $patient
      payment_method: $payment_method
      shipping_method: $shipping_method
      subscription: $subscription
      is_subscription: $is_subscription
      is_doctor: $is_doctor
      requires_prescription: $requires_prescription
      has_invoicing: $has_invoicing
      has_cfdi: $has_cfdi
      origin: $origin
      has_enlace_vital: $has_enlace_vital
      has_cdso: $has_cdso
      has_loyalty_error: $has_loyalty_error
      in_process_date: $in_process_date
      max_delivery_date: $max_delivery_date
      expected_delivery_date: $expected_delivery_date
      supervised_by: $supervised_by
      driver: $driver
      distribution_center: $distribution_center
      exclude_order_status__in: $exclude_order_status__in
      credit_date: $credit_date
      payment_date: $payment_date
      is_special_pickup: $is_special_pickup
      store: $store
      external_payment_id: $external_payment_id
      detail_ean_key: $detail_ean_key
      supervised_by: $supervised_by
    )
      @rest(type: "OrdersPayload", path: "/orders/?{args}")
      @connection(key: "ordersPaginated") {
      count
      next
      previous
      results {
        ...OrderFragment
      }
    }
  }

  ${fragments.order}
`;

export const GET_ORDERS_BY_ID = gql`
  query order($ids: String!) {
    orders(ids: $ids)
      @rest(type: "Orders", path: "/orders/?id__in={args.ids}") {
      ...OrderFragment
    }
  }
  ${fragments.order}
`;

export const PURCHASE_ORDERS_GET_PAGINATED = gql`
  query purchaseOrdersPaginated(
    $limit: String
    $page: String
    $search: String
    $store: String
    $all: Boolean
    $status__in: String
    $supplier: String
    $exclude_status__in: String
    $created_at_after: String
    $created_at_before: String
    $detail_ean_key: String
  ) {
    purchaseOrdersPaginated(
      limit: $limit
      page: $page
      search: $search
      store: $store
      all: $all
      status__in: $status__in
      supplier: $supplier
      exclude_status__in: $exclude_status__in
      created_at_after: $created_at_after
      created_at_before: $created_at_before
      detail_ean_key: $detail_ean_key
    )
      @rest(type: "PurchaseOrdersPayload", path: "/purchase-orders/?{args}")
      @connection(key: "purchaseOrdersPaginated") {
      count
      next
      previous
      results {
        id
        details
        created_at
        updated_at
        status
        external_id
        invoice_code
        expected_delivery_date
        subtotal
        iva
        total
        supplier
        store
        payment_status
        created_by
        updated_by
        bought_for
      }
    }
  }
  ${fragments.purchaseOrder}
`;

export const PURCHASE_ORDER_GET_SPECIFIC = gql`
  query specificPurchaseOrder($id: String!) {
    specificPurchaseOrder(id: $id)
      @rest(
        type: "SpecificPurchaseOrder"
        path: "/purchase-orders/{args.id}/"
      ) {
      id
      details
      created_at
      updated_at
      status
      external_id
      invoice_code
      expected_delivery_date
      subtotal
      iva
      total
      supplier
      store
      created_by
      updated_by
      bought_for
    }
  }
  ${fragments.purchaseOrder}
`;

export const GET_PRODUCTS_PENDING_FULFILLMENT = gql`
  query productsPendingFulfillment(
    $purchase_order: String
    $store: String
  ) {
    productsPendingFulfillment(
      purchase_order: $purchase_order
      store: $store
    )
      @rest(type: "productPendingFulfillment", path: "/purchase-orders/products-pending-fulfillment/?{args}"){
      product_id
      sum_pending
    }
  }
  ${fragments.purchaseOrder}
`

export const ORDER_GET_SPECIFIC = gql`
  query specificOrder($id: String!) {
    specificOrder(id: $id)
      @rest(type: "SpecificOrder", path: "/orders/{args.id}/") {
      ...SpecificOrderFragment
    }
  }

  ${fragments.specificOrder}
`;

export const ORDER_GET_SPECIFIC_HISTORY = gql`
  query orderHistory($id: String!) {
    orderHistory(id: $id)
      @rest(type: "[SpecificOrder]", path: "/orders/{args.id}/historical")
      @connection(key: "orderHistory") {
      ...SpecificOrderFragment
      history_user
    }
  }

  ${fragments.specificOrder}
`;

export const USER_GET_PAYMENTS = gql`
  query payments($userId: String!) {
    payments(userId: $userId)
      @rest(type: "[Payment]", path: "/payments/?user={args.userId}") {
      id
      cardholder
      customer_id
      date_created
      date_last_updated
      expiration_month
      expiration_year
      first_six_digits
      issuer
      last_four_digits
      payment_id
      payment_method
      security_code
      user_id
    }
  }
`;

export const PAYMENT_METHODS_GET = gql`
  query paymentMethods {
    paymentMethods @rest(type: "[PaymentMethod]", path: "/payment-methods") {
      id
      name
    }
  }
`;

export const INVOICE_GET = gql`
  query downloadInvoice($orderId: String, $fileName: String) {
    downloadInvoice(orderId: $orderId, fileName: $fileName)
      @rest(
        type: "InvoiceFile"
        path: "/orders/{args.orderId}/download-invoice/?file_format=pdf&file_name={args.fileName}"
      ) {
      data
    }
  }
`;

export const IDENTIFICATION_GET = gql`
  query identification($id: String) {
    identification(id: $id)
      @rest(
        type: "PrescriptionIdentification"
        path: "/identifications/{args.id}/"
      ) {
      id
      created_at
      deleted_at
      document
      document_type
      expiration_date
      full_name
      number
      patient
      updated_at
    }
  }
`;

export const IDENTIFICATIONS_GET = gql`
  query identifications($id: String) {
    identifications(id: $id)
      @rest(
        type: "PrescriptionIdentification"
        path: "/identifications/?patient={args.id}"
      ) {
      id
      patient
      created_at
      updated_at
      deleted_at
      full_name
      expiration_date
      document
      document_type
      number
    }
  }
`;
export const IDENTIFICATIONS_EMPLOYEE_GET = gql`
  query identifications($id: String, $document_type: String) {
    identifications(id: $id, document_type: $document_type)
      @rest(
        type: "PrescriptionIdentification"
        path: "/identifications/?patient={args.id}&document_type={args.document_type}"
      ) {
      id
      patient
      created_at
      updated_at
      deleted_at
      full_name
      expiration_date
      document
      document_type
      number
    }
  }
`;

export const IDENTIFICATION_DELETE = gql`
  mutation deleteIdentification($id: String) {
    deleteIdentification(id: $id)
      @rest(
        type: "PrescriptionIdentification"
        path: "/identifications/{args.id}/"
        method: "DELETE"
      ) {
      id
    }
  }
`;

export const IDENTIFICATION_CREATE = gql`
  mutation createIdentification($input: IdentificationInput) {
    createIdentification(input: $input)
      @rest(
        type: "Identification"
        path: "/identifications/"
        method: "POST"
        bodySerializer: "fileEncode"
      ) {
      id
      document
      expiration_date
      patient
      created_at
      updated_at
      deleted_at
    }
  }
`;
